import { useQueryClient } from "@tanstack/react-query"
import { Formik, Form, FormikHelpers } from "formik"

import { SubmitButton } from "../../components/SubmitButton"
import { Toast } from "../../components/Toast"
import { genericErrMsg } from "../../api/auth"
import { useUnlockDisqualifyAccountProjectReachout } from "../../hooks"
import { usePartnersStore } from "../../stores/usePartnersStore"
import { AxiosError } from "axios"
import { EligibleLeadTypes, ReachoutsCounterTypes } from "@/types/partners"

interface PartnersEligibleLeadsUnlockDisqualifyTypes {
  action: "disqualify" | "unlock"
  handleSelectRow: (row: EligibleLeadTypes | null) => void
  reachoutsCounter: ReachoutsCounterTypes | undefined
}

interface PartnersEligibleLeadsUnlockDisqualifyFormValuesTypes {
  action: "unlock" | "disqualify"
}

const PartnersEligibleLeadsUnlockDisqualify = ({
  action,
  handleSelectRow,
  reachoutsCounter,
}: PartnersEligibleLeadsUnlockDisqualifyTypes) => {
  const queryClient = useQueryClient()
  const { activeRow, setEligibleActiveRowUnlocked } = usePartnersStore()

  const { mutateAsync: unlockDisqualifyPartnersProjectEligibleLead } =
    useUnlockDisqualifyAccountProjectReachout(
      queryClient,
      activeRow?.id as number,
      {
        onSuccess: () => {
          Toast.success(
            action === "unlock"
              ? "Lead has been unlocked successfuly"
              : "Lead has been disqualified successfuly. Additional unlock available."
          )

          if (action === "unlock") {
            setEligibleActiveRowUnlocked(true)
          } else {
            handleSelectRow(null)
          }
        },
        onError: (error: AxiosError) => {
          Toast.error(error?.message || genericErrMsg)
        },
      }
    )

  const handleOnSubmit = async (
    values: PartnersEligibleLeadsUnlockDisqualifyFormValuesTypes,
    {
      resetForm,
    }: FormikHelpers<PartnersEligibleLeadsUnlockDisqualifyFormValuesTypes>
  ) => {
    await unlockDisqualifyPartnersProjectEligibleLead(values)
    resetForm()
  }

  if (activeRow === null) {
    return null
  }

  return (
    <div className="relative w-full card card-content self-start overflow-hidden">
      <h4 className="sticky -top-4 left-0 right-0 bg-cloud-50 text-overline text-dusk-500 p-4 -m-4 mb-0 border-b-1 border-b-cloud z-2">
        {action === "unlock" ? "Unlock" : "Disqualify"}
      </h4>

      {action === "disqualify" ? (
        <p className="label leading-18px font-normal mt-4">
          Not interested? Disqualify landowners to hide them from the table and
          access another unlock.
        </p>
      ) : null}

      <Formik
        initialValues={{
          action,
        }}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={handleOnSubmit}
        enableReinitialize
      >
        {(formikProps) => (
          <Form>
            <SubmitButton
              className="btn2 btn2-primary btn2-block font-semibold mt-4"
              isSubmitting={formikProps.isSubmitting}
              disabled={
                action === "unlock" &&
                reachoutsCounter &&
                reachoutsCounter.unlocks_remaining < 1
              }
            >
              {action === "unlock" ? "Unlock" : "Disqualify"}
            </SubmitButton>
          </Form>
        )}
      </Formik>

      {action === "unlock" ? (
        <p className="text-sm text-dusk text-right leading-130 tracking-0.14 mt-4">
          Uses remaining: {reachoutsCounter?.unlocks_remaining}
        </p>
      ) : null}
    </div>
  )
}

export default PartnersEligibleLeadsUnlockDisqualify
