import { Dispatch, SetStateAction } from "react"
import bbox from "@turf/bbox"
import {
  FeatureCollection,
  Feature,
  Geometry,
  Position,
  Properties,
} from "@turf/helpers"
import simplify from "@turf/simplify"
import truncate from "@turf/truncate"

import { DEFAULT_ZOOM_LEVEL } from "../../../shared/utils"
import { ViewportTypes } from "@/types"

export const getViewportFromFeature = (
  feature: Feature<Geometry, Properties>,
  width: number = 600,
  height: number = 400
) => {
  const bounds = bbox({
    type: "FeatureCollection",
    features: [feature],
  })

  const [minLng, minLat, maxLng, maxLat] = bounds

  const longitude = (minLng + maxLng) / 2
  const latitude = (minLat + maxLat) / 2

  // DEV: Calculate the zoom level based on the bounding box
  const deltaLng = maxLng - minLng
  const deltaLat = maxLat - minLat

  // Calculate zoom levels based on the width and height of the map
  const zoomWidth = Math.floor(Math.log2(((width / deltaLng) * 360) / 512))
  const zoomHeight = Math.floor(Math.log2(((height / deltaLat) * 170) / 512))

  const zoom = Math.min(zoomWidth, zoomHeight, 20)

  return {
    longitude,
    latitude,
    zoom: zoom || DEFAULT_ZOOM_LEVEL,
  }
}

const getPreviousValidFeatureIndex = (
  features: Feature<Geometry, Properties>[],
  currentIndex: number
) => {
  const previousIndex = features
    .slice(0, currentIndex)
    .reverse()
    .findIndex(
      (feature: Feature<Geometry, Properties>) =>
        feature?.properties?.elig_acres !== 0
    )

  return previousIndex !== -1 ? currentIndex - previousIndex - 1 : currentIndex
}

export const handlePrevFeature = (
  setActiveFeature: Dispatch<SetStateAction<number | null>>,
  setViewport: (viewport: ViewportTypes) => void,
  parcelData: FeatureCollection<Geometry, Properties> | undefined
) => {
  setActiveFeature((oldFeature: number | null) => {
    const previousValidIndex = getPreviousValidFeatureIndex(
      parcelData?.features || [],
      oldFeature as number
    )

    if (previousValidIndex !== oldFeature) {
      setViewport(
        getViewportFromFeature(parcelData!.features[previousValidIndex])
      )
    }

    return previousValidIndex
  })
}

const getNextValidFeatureIndex = (
  features: Feature<Geometry, Properties>[],
  currentIndex: number
) => {
  const nextIndex = features.findIndex(
    (feature, index) =>
      index > currentIndex && feature?.properties?.elig_acres !== 0
  )

  return nextIndex !== -1 ? nextIndex : currentIndex
}

export const handleNextFeature = (
  setActiveFeature: Dispatch<SetStateAction<number | null>>,
  setViewport: (viewport: ViewportTypes) => void,
  parcelData: FeatureCollection<Geometry, Properties> | undefined
) => {
  setActiveFeature((oldFeature: number | null) => {
    const nextValidIndex = getNextValidFeatureIndex(
      parcelData?.features || [],
      oldFeature as number
    )

    if (nextValidIndex !== oldFeature) {
      setViewport(getViewportFromFeature(parcelData!.features[nextValidIndex]))
    }

    return nextValidIndex
  })
}

export function simplifyGeoJSON(
  geojson: FeatureCollection<Geometry, Properties>
): FeatureCollection<Geometry, Properties> {
  let simpleGeoJSON: FeatureCollection<Geometry, Properties>
  const maxCoordinates = 50
  const coordinates: Position[][] | Position[][][] = geojson.features[0]
    .geometry.coordinates as Position[][] | Position[][][]

  const numCoordinates = coordinates.flat(2).length

  if (numCoordinates <= maxCoordinates) {
    simpleGeoJSON = geojson
  } else {
    simpleGeoJSON = simplify(geojson, { tolerance: 0.0005 })
  }
  return truncate(simpleGeoJSON, { precision: 5, coordinates: 2 })
}
