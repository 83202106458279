import { useRef, useState, useEffect } from "react"
import * as Sentry from "@sentry/react"
import "mapbox-gl/dist/mapbox-gl.css"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"
import mapboxglSupported from "@mapbox/mapbox-gl-supported"
import bbox from "@turf/bbox"
import bboxPolygon from "@turf/bbox-polygon"
import booleanIntersects from "@turf/boolean-intersects"
import { useMount } from "react-use"
import { parseDms } from "dms-conversion/dist/esm/dms"
import { useQueryClient } from "@tanstack/react-query"

import MapboxGLNotSupported from "./MapboxGLNotSupported"
import { Toast } from "../Toast"
import { PARCEL_LAYER_MIN_ZOOM } from "../ParcelSelectLayer"
import { getAddressLatLng } from "../../api/data"
import { fetchParcelData } from "../../hooks"
import { wktArrToFeatures } from "../../utils"
import { isDMS } from "../../shared/utils"
import { clipSelectedParcelsFromExistingFeatures } from "./helpers"
import { WEBGL_NOT_SUPPORTED } from "./constants"
import { LayerType } from "../../types/mapbox"
import { BBox, Feature } from "@turf/helpers"
import { DataTypes, DrawRef, MapVisualizationTypes, PayloadType } from "./types"
import { FeatureType } from "@/types"
import MapVisualizationMap from "./MapVisualizationMap"

const unitedStatesOutlinePolygon = import(
  "../../fixtures/united-states-outline-polygon.json"
)

export const MapVisualization = ({
  features,
  incrementNumParcelRequests,
  decrementNumParcelRequests,
  viewport,
  setViewport,
  onFeatureCreate,
  onFeatureUpdate,
  onFeatureDelete,
  onFeatureClear,
  onViewportChange,
  showParcelSelect,
  showParcelDraw,
  showParcelUpload,
  uploadDialog,
  decodedAddress,
  displayMapEditingTools,
  ref,
}: MapVisualizationTypes) => {
  const queryClient = useQueryClient()
  const mapContainerRef = useRef<HTMLDivElement>(null)
  const drawRef: DrawRef = useRef(null)
  const [mode, setMode] = useState<string>("simple_select")
  const [modeOptions, setModeOptions] = useState({})
  const [parcelSelectState, setParcelSelectState] = useState({})
  const [selectedFeatures, setSelectedFeatures] = useState<Feature[]>([])
  const [search, setSearch] = useState("")
  const [layer, setLayer] = useState<LayerType>("aerial")

  const handleSearchSelect = async (address: string) => {
    if (address?.trim() !== "") {
      try {
        const { lat, lng } = await getAddressLatLng(address)
        setViewport({
          latitude: lat,
          longitude: lng,
          zoom: 15,
        })
        setSearch(address)
      } catch (error) {
        if (typeof error === "string") {
          // https://developers.google.com/maps/documentation/javascript/places#place_details_responses
          if (error === "ZERO_RESULTS") {
            // abbrs.: DMS (Degrees/Minutes/Seconds), DD (Decimal Degrees)
            // at this point, it's either no results because input
            // does not have a matching address, or DMS format is entered

            // if input value is in DMS, convert to DD and perform another search
            if (isDMS(address)) {
              const DMSToDD = address
                .split(",")
                .map((item) => parseDms(item.trim()))
                .join(",")

              handleSearchSelect(DMSToDD)
            } else {
              // if input value is not DMS, then display error message
              Toast.error("The address you entered could not be found.", 2500)
            }
            return
          } else if (
            // "ERROR" not documented but it exists: https://sentry.io/organizations/silviaterra/issues/2366387495/
            ["ERROR", "OVER_QUERY_LIMIT", "UNKNOWN_ERROR"].includes(error)
          ) {
            Toast.error(
              "Unable to look up address at this time. Try again later.",
              2500
            )
            return
          }
          // Upcast error string to a stacktraceable error for Sentry
          //   "Non-Error promise rejection captured with value:", https://sentry.io/organizations/silviaterra/issues/2352865303/
          throw new Error("Error in handleSearchSelect: " + error)
        } else {
          throw error
        }
      }
    }
  }

  const handleDrawDelete = (payload: PayloadType) => {
    if (drawRef.current) {
      setSelectedFeatures(
        drawRef.current.getDraw().getSelected().features as Feature[]
      )
    }
    // @ts-ignore
    onFeatureDelete(payload.features)
  }

  const handleDrawCreate = (payload: PayloadType) => {
    onFeatureCreate(payload.features)
  }

  const handleDrawUpdate = (payload: PayloadType) => {
    onFeatureUpdate(payload.features)
  }

  // DEV: This only fires from draw specific events
  //   https://github.com/urbica/react-map-gl-draw/blob/v0.3.5/src/components/Draw/index.js#L174-L188
  const handleDrawModeChange = ({ mode }: { mode: string }) => {
    if (mode === "direct_select") {
      return
    }
    setMode(mode)
  }

  const handleDrawSelectionChange = async (data: DataTypes) => {
    if (mode === "parcel_select") {
      // data = { action, parcelId, reactState }, defined by "draw.selectionchange" in `ParcelSelectLayer`
      //   also { target, type }, provided by <Draw>
      // DEV: We lose Sentry scope from `ParcelSelectLayer` due to <Draw> running this as a callback, so rebuild them
      // DEV: We need to use `try/catch` + report as we'll return to outer scope execution (thus losing newly pushed scope)
      try {
        setParcelSelectState(data.reactState)
        const selectedParcelIds = [data.parcelId]
        incrementNumParcelRequests?.()

        if (data.action === "add") {
          await handleParcelAdd(selectedParcelIds)
        } else if (data.action === "delete") {
          await handleParcelDelete(selectedParcelIds)
        } else {
          throw new Error(`Unrecognized action ${data.action}`)
        }

        decrementNumParcelRequests?.()
      } catch (err) {
        Sentry.getCurrentScope().setContext("parcel_select", {
          action: data.action,
          parcelId: data.parcelId,
        })
        throw err
      }
      // DEV: We would be running a `data.onSuccess()` handler here (hence await's)
      //   but timing of React scheduler + MapBox GL requestAnimationFrame lead to inconsistencies
      //   See further explanation at `onSuccessCallbacks.push` in `ParcelSelectLayer`
    } else {
      // features is an array of geojson features
      setSelectedFeatures(data.features)
    }
  }

  // When different map menu buttons should be displayed
  const showMenuSelect = () =>
    mode === "simple_select" &&
    features?.length !== 0 &&
    selectedFeatures.length === 0
  const showMenuParcelSelect = () =>
    mode === "simple_select" &&
    selectedFeatures.length === 0 &&
    showParcelSelect
  const showMenuDrawPolygon = () =>
    mode === "simple_select" && selectedFeatures.length === 0 && showParcelDraw
  const showMenuFileUpload = () =>
    mode === "simple_select" &&
    selectedFeatures.length === 0 &&
    showParcelUpload
  const showMenuEdit = () => selectedFeatures.length !== 0
  const showMenuTrash = () => selectedFeatures.length !== 0
  const showMenuBack = () =>
    mode !== "simple_select" || selectedFeatures.length !== 0
  const showMenuClear = () =>
    mode === "simple_select" &&
    features?.length !== 0 &&
    selectedFeatures.length === 0

  const handleMenuSelect = () => {
    setMode("simple_select")
    setModeOptions({})
  }

  const checkIfViewportInUSA = async () => {
    const unitedStatesOutline = await unitedStatesOutlinePolygon

    const map = ref.current?.getMap()
    const viewportBbox = bboxPolygon(map.getBounds().toArray().flat() as BBox)

    if (
      !unitedStatesOutline.features.some((feature) =>
        booleanIntersects(feature as FeatureType, viewportBbox)
      )
    ) {
      Toast.error(
        "This search area is outside of the NCX program area - we are not available outside the contiguous United States. Sorry!"
      )
    }

    map.off("zoomend", checkIfViewportInUSA)
  }

  const handleMenuParcelSelect = () => {
    const map = ref.current?.getMap()
    if (map && map.getZoom() < PARCEL_LAYER_MIN_ZOOM) {
      map.zoomTo(PARCEL_LAYER_MIN_ZOOM, {
        duration: 1000 * (PARCEL_LAYER_MIN_ZOOM - map.getZoom()),
      })
    }

    map.on("zoomend", checkIfViewportInUSA)

    setMode("parcel_select")
    setModeOptions({
      reactState: parcelSelectState,
    })
  }

  const handleParcelAdd = async (selectedParcelIds: string[]) => {
    const parcelWktArr = await fetchParcelData(queryClient, selectedParcelIds)
    const features = wktArrToFeatures(parcelWktArr)
    handleDrawCreate({ features: features })
  }

  const handleParcelDelete = async (selectedParcelIds: string[]) => {
    const parcelWktArr = await fetchParcelData(queryClient, selectedParcelIds)
    const parcelFeatures = wktArrToFeatures(parcelWktArr)
    const updatedFeatures = clipSelectedParcelsFromExistingFeatures({
      features,
      parcelFeatures,
    })
    handleDrawUpdate({ features: updatedFeatures })
  }

  const handleMenuDrawPolygon = () => {
    checkIfViewportInUSA()
    setModeOptions({})
    setMode("draw_polygon")
  }

  const handleMenuEdit = () => {
    setMode("direct_select")
    setModeOptions({ featureId: selectedFeatures[0].id })
  }

  const handleMenuTrash = () => drawRef.current?.getDraw()?.trash()

  const handleMenuBack = () => {
    // Reset is a dummy mode that's the same as simple_select. The problem is
    // if the user is already in simple_select mode and has a feature selected,
    // clicking the Back button won't deselect the feature if we set the mode
    // directly to simple_select. Setting to "reset" instead will register as a
    // mode change in MapboxDraw and do the proper cleanup (deselecting features).
    setMode(mode === "simple_select" ? "reset" : "simple_select")
    setModeOptions({})
    setSelectedFeatures([])
  }

  const handleMenuFileUpload = () => {
    setMode("file_upload")
    if (uploadDialog) {
      uploadDialog.show()
    }
  }

  useMount(() => {
    if (ref.current) {
      const map = ref.current.getMap()

      if (features.length > 0) {
        map.fitBounds(
          bbox({
            type: "FeatureCollection",
            features,
          }),
          {
            padding: 40,
          }
        )
      }
    }
  })

  useEffect(() => {
    // If clear bounds is clicked, reset selected features
    if (features?.length === 0) {
      setSelectedFeatures([])
    }
  }, [features])

  useEffect(() => {
    if (decodedAddress) {
      setSearch(decodedAddress)
      // set the parcel select mode
      setMode("parcel_select")
      setModeOptions({
        reactState: parcelSelectState,
      })
    }
  }, [decodedAddress, setSearch, parcelSelectState])

  useEffect(() => {
    const map = ref.current?.getMap()
    if (map) {
      if (mode === "draw_polygon") {
        map.dragPan.disable()
      } else {
        map.dragPan.enable()
      }
    }

    // See handleMenuBack above for why there is a reset mode.
    // It's a proxy for going back to simple_select mode, so we set it back here.
    if (mode === "reset") {
      setMode("simple_select")
    }
  }, [ref, mode])

  // DEV: focus on map when map is open for accessibility purposes
  useEffect(() => {
    if (
      mapContainerRef.current &&
      typeof mapContainerRef.current !== "undefined"
    ) {
      mapContainerRef.current.focus()
    }
  }, [])

  if (mapboxglSupported.supported()) {
    return (
      <MapVisualizationMap
        ref={mapContainerRef}
        mapRef={ref}
        drawRef={drawRef}
        layer={layer}
        viewport={viewport}
        mode={mode}
        onViewportChange={onViewportChange}
        features={features}
        displayMapEditingTools={displayMapEditingTools}
        modeOptions={modeOptions}
        handleDrawSelectionChange={(data: DataTypes) => {
          handleDrawSelectionChange(data)
        }}
        handleDrawDelete={handleDrawDelete}
        handleDrawCreate={handleDrawCreate}
        handleDrawUpdate={handleDrawUpdate}
        handleDrawModeChange={handleDrawModeChange}
        showMenuSelect={showMenuSelect}
        showMenuParcelSelect={showMenuParcelSelect}
        showMenuDrawPolygon={showMenuDrawPolygon}
        showMenuEdit={showMenuEdit}
        showMenuTrash={showMenuTrash}
        showMenuBack={showMenuBack}
        showMenuClear={showMenuClear}
        handleMenuParcelSelect={handleMenuParcelSelect}
        handleMenuDrawPolygon={handleMenuDrawPolygon}
        handleMenuEdit={handleMenuEdit}
        handleMenuTrash={handleMenuTrash}
        handleMenuBack={handleMenuBack}
        handleMenuFileUpload={handleMenuFileUpload}
        onFeatureClear={onFeatureClear}
        showMenuFileUpload={showMenuFileUpload}
        handleMenuSelect={handleMenuSelect}
        search={search}
        setSearch={setSearch}
        handleSearchSelect={(address: string) => {
          handleSearchSelect(address)
        }}
        setLayer={setLayer}
        setMode={setMode}
      />
    )
  } else {
    const reason = mapboxglSupported.notSupportedReason()
    try {
      return <MapboxGLNotSupported reason={reason} />
    } finally {
      if (reason !== WEBGL_NOT_SUPPORTED) {
        throw new Error("Mapbox GL unsupported - " + reason)
      }
    }
  }
}
