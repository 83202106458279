// will replace pages/SignUp.js
// For signing up a new user either by form or Sso
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { useNavigate } from "react-router"
import { Formik, FormikValues } from "formik"
import * as Yup from "yup"
import { OptimizelyContext } from "@optimizely/react-sdk"

import { genericErrMsg } from "../../api/auth"
import {
  identifyUserForIntegrations,
  sendAnalyticsEvent,
  heapTrackEvent,
} from "../../api/integrations"
import { useCreateUser, useLoginWithGoogle } from "../../hooks"
import SignUpFormComponent from "./SignUpFormComponent"
import { useShowProfileType } from "../../hooks/useShowProfileType"
import { useSessionStore } from "../../stores"
import { AuthDataTypes } from "@/types/auth"

export interface SignUpFormTypes {
  authData: AuthDataTypes
  inputEmail: string
  setIsUserCreated: Dispatch<SetStateAction<boolean>>
  setInputEmail: Dispatch<SetStateAction<string>>
  setAuthData: Dispatch<SetStateAction<AuthDataTypes>>
}

const validationSchema = Yup.object().shape({
  profile_type: Yup.string().required("Please choose an account type."),
  first_name: Yup.string().required("Please provide your first name."),
  last_name: Yup.string().required("Please provide your last name."),
  email: Yup.string()
    .email("Please provide a valid email address.")
    .required("Please provide your email address."),
  password: Yup.string()
    .required("Please provide a password.")
    .min(8, "Password must be at least 8 characters."),
})

const SignUpForm = ({
  authData,
  inputEmail,
  setAuthData,
  setIsUserCreated,
}: SignUpFormTypes) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { optimizely } = useContext(OptimizelyContext)
  const { identified, setIdentified } = useSessionStore((state) => state)

  const navigate = useNavigate()
  const showProfileType = useShowProfileType()

  const { mutateAsync: createUser } = useCreateUser({
    onSuccess: (_data: any, variables: { email: string }) => {
      identifyUserForIntegrations(
        variables.email,
        optimizely,
        identified,
        setIdentified
      )
      sendAnalyticsEvent({ action: "signup", label: "Landowners Signup" })
      sendAnalyticsEvent({
        action: "lp_email_reg",
        label: "Logical Position: Landowners Signup with email",
      })
      setIsUserCreated(true)
    },
    onError: (error: { detail: string }) => {
      setErrorMessage(error?.detail || genericErrMsg)
    },
  })

  const { mutateAsync: loginWithGoogle } = useLoginWithGoogle({
    /*
     * data is from api response (our jwt info + user info),
     * variables is what we send in onSubmit (google token)
     */
    onSuccess: (data: { email: string; new_sign_up: boolean }) => {
      identifyUserForIntegrations(
        data.email,
        optimizely,
        identified,
        setIdentified
      )
      if (data.new_sign_up) {
        sendAnalyticsEvent({
          action: "lp_google_reg",
          label: "Logical Position: Landowner Signup with Social",
        })
      } else {
        sendAnalyticsEvent({
          action: "login_google",
          label: "Landowner Log-in with Social",
        })
      }
      heapTrackEvent("google_login", {
        authStep: "sign-up",
        newUser: !!data.new_sign_up,
      })
      setIsUserCreated(true)
      // we go right to onboarding because we verify email on Sso signup
      navigate("/onboarding")
    },
    onError: () => {
      setErrorMessage(genericErrMsg)
    },
  })

  const handleSubmitSso = async (values: { accessToken: string }) => {
    await loginWithGoogle(values)
  }

  const onSubmit = async (_values: FormikValues) => {
    const values = { ..._values }
    await createUser(values)
  }

  return (
    <Formik
      initialValues={{
        // DEV: We must provide a default value when no combobox, as hidden inputs aren't picked up for validation
        profile_type: showProfileType ? "" : "single_account",
        first_name: "",
        last_name: "",
        email: inputEmail || "",
        password: "",
        referral_source: "",
        ...authData,
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <SignUpFormComponent
          {...formikProps}
          errorMessage={errorMessage}
          setAuthData={setAuthData}
          onSsoSuccess={(values: { accessToken: string }) => {
            handleSubmitSso(values)
          }}
          onSocialError={() => setErrorMessage(genericErrMsg)}
          inputEmail={inputEmail}
        />
      )}
    </Formik>
  )
}
export default SignUpForm
