import { Dispatch, SetStateAction } from "react"
import { QueryStatus } from "@tanstack/react-query"
import { RowSelectionState } from "@tanstack/react-table"

import PartnerAccountUpdateStatus from "./PartnersAccountUpdateStatus"
import PartnersAccountInfo from "./PartnersAccountInfo"
import PartnersAccountProfiles from "./PartnersAccountProfiles"
import PartnersAccountAttestations from "./PartnersAccountAttestations"
import PartnersInterestedPrograms from "./PartnersInterestedPrograms"
import { usePartnersStore } from "../../stores/usePartnersStore"
import { QUALIFIED_LEADS } from "../../shared/constants"
import { PartnersLandownerTypes } from "@/types/partners"

interface PartnersAccountTypes {
  unlocked?: boolean
  account: PartnersLandownerTypes | undefined
  accountStatus: QueryStatus
  selectedRows?: RowSelectionState
  setRowSelection?: Dispatch<SetStateAction<RowSelectionState>>
}

const PartnersAccount = ({
  unlocked = true,
  account,
  accountStatus,
  selectedRows,
  setRowSelection,
}: PartnersAccountTypes) => {
  const { activeTab } = usePartnersStore()

  return (
    <div className="flex flex-col gap-6">
      {activeTab === QUALIFIED_LEADS ? (
        <PartnerAccountUpdateStatus
          accountStatus={accountStatus}
          selectedRows={selectedRows}
          onUpdateSuccess={() => {
            if (setRowSelection) {
              setRowSelection({})
            }
          }}
        />
      ) : null}

      {unlocked ? (
        <>
          <PartnersInterestedPrograms />

          <PartnersAccountInfo
            primaryContact={account?.profiles[0]}
            accountStatus={accountStatus}
          />

          <PartnersAccountProfiles
            profiles={account?.profiles}
            accountStatus={accountStatus}
          />
        </>
      ) : null}

      <PartnersAccountAttestations
        attestations={account?.attestations}
        accountStatus={accountStatus}
      />
    </div>
  )
}

export default PartnersAccount
