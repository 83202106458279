import { memo, useState, useCallback, useEffect } from "react"

interface CountdownTimerProps {
  date: string
  className?: string
  onTimerComplete?: () => void
}

const CountdownTimer = memo(
  ({ date, onTimerComplete, className }: CountdownTimerProps) => {
    const [countdown, setCountdown] = useState("")

    const calculateCountdown = useCallback(() => {
      const targetDate = new Date(date)
      const now = new Date()
      const timeDifference = targetDate.getTime() - now.getTime()

      if (timeDifference <= 0) {
        onTimerComplete && onTimerComplete()

        return "00:00:00:00"
      }

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
      const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24)
      const minutes = Math.floor((timeDifference / (1000 * 60)) % 60)
      const seconds = Math.floor((timeDifference / 1000) % 60)

      return `${String(days).padStart(2, "0")}:${String(hours).padStart(
        2,
        "0"
      )}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
        2,
        "0"
      )}`
    }, [onTimerComplete, date])

    useEffect(() => {
      let animationFrameId: number

      const updateCountdown = () => {
        setCountdown(calculateCountdown())
        animationFrameId = requestAnimationFrame(updateCountdown)
      }

      updateCountdown()

      return () => {
        cancelAnimationFrame(animationFrameId)
      }
    }, [calculateCountdown])

    if (countdown === "00:00:00:00") {
      return null
    }

    return <span className={className}>{countdown}</span>
  }
)

export default CountdownTimer
