import { MouseEventHandler } from "react"

interface resetColumnFiltersTypes {
  resetColumnFilters: (defaultState?: boolean) => void
}

const TableFiltersReset = ({ resetColumnFilters }: resetColumnFiltersTypes) => (
  <div className="w-[830px] max-w-full text-left p-3 border-t-1 border-t-cloud">
    <button
      className="hover:opacity-80 transition-opacity duration-200"
      onClick={
        resetColumnFilters as unknown as MouseEventHandler<HTMLButtonElement>
      }
    >
      Reset Filters
    </button>
  </div>
)

export default TableFiltersReset
