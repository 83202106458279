import assert from "assert"
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import { FormikValues } from "formik"

import { useSessionStore } from "../stores"
import { AuthDataTypes, ChangePasswordVariables } from "../types/auth"

export const ncapxPlatformURL =
  import.meta.env.VITE_APP_BARK_API_ROOT_URL + "ncapx_platform/"
const createUserURL = ncapxPlatformURL + "create_user/"
const getTokenURL = import.meta.env.VITE_APP_BARK_API_ROOT_URL + "api/token/"
const refreshTokenURL =
  import.meta.env.VITE_APP_BARK_API_ROOT_URL + "api/token/refresh/"
const resetPasswordURL =
  import.meta.env.VITE_APP_BARK_API_ROOT_URL + "api/password_reset/"
const resetPasswordConfirmURL =
  import.meta.env.VITE_APP_BARK_API_ROOT_URL + "api/password_reset/confirm/"
const logoutURL = import.meta.env.VITE_APP_BARK_API_ROOT_URL + "api/logout/"
const loginAsURL = import.meta.env.VITE_APP_BARK_API_ROOT_URL + "api/login_as/"
const checkEmailURL =
  import.meta.env.VITE_APP_BARK_API_ROOT_URL + "api/email_check"
const socialAuthURL = ncapxPlatformURL + "social_auth/"
const googleAuthURL = socialAuthURL + "google"

export const genericErrMsg =
  "Something went wrong, please try again or reach out to us at landowners@ncx.com"

export const wrappedAxios = async (
  methodName: "get" | "delete" | "post" | "put",
  ...args: [string, FormikValues, AxiosRequestConfig?]
): Promise<AxiosResponse<any>> => {
  let res: AxiosResponse<any>
  try {
    res = await axios[methodName](...args)
  } catch (error: any) {
    // Django REST Framework error format `{detail: "Error message"}`
    if (error.response?.data?.detail) {
      error.detail = error.response.data.detail
    } else if (error.response?.data?.error) {
      // Deprecated: Custom `error_response` format
      error.detail = error.response.data.error
    }
    throw error
  }
  return res
}

// Create a user via bark-api
export const createUser = async (values: FormikValues): Promise<any> => {
  const res = await wrappedAxios("post", createUserURL, values, {
    withCredentials: true,
  })
  return res.data
}

export const googleLogin = async ({
  accessToken,
}: {
  accessToken: string
}): Promise<any> => {
  // we decrypt the JWT token from google on server side
  const res = await wrappedAxios(
    "post",
    googleAuthURL,
    {
      token: accessToken,
    },
    {
      withCredentials: true,
    }
  )
  return res.data
}

// Check if a user exists via bark-api for given email
export const checkUserByEmail = async ({
  email,
}: AuthDataTypes): Promise<any> => {
  const res = await wrappedAxios("post", checkEmailURL, {
    email: email,
  })
  return res.data
}

// Log in to bark-api and return [accessToken, refreshToken].
// Throws if authentication fails or if server is not reachable.
export const login = async ({
  email,
  password,
}: AuthDataTypes): Promise<any> => {
  const res = await wrappedAxios(
    "post",
    getTokenURL,
    {
      username: email,
      password: password,
    },
    {
      withCredentials: true,
    }
  )
  return res.data
}

// Logging out tells bark-api to invalidate the refresh token and expire the rt cookie
export const logout = async (): Promise<AxiosResponse<any>> => {
  const accessToken = useSessionStore.getAccessToken()
  return await wrappedAxios(
    "post",
    logoutURL,
    {},
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      withCredentials: true,
    }
  )
}

export const resetPassword = async (values: AuthDataTypes): Promise<any> => {
  const res = await wrappedAxios("post", resetPasswordURL, values, {
    withCredentials: true,
  })
  return res.data
}

export const resetPasswordConfirm = async (values: {
  password: string
  token: string | null
}): Promise<any> => {
  const res = await wrappedAxios("post", resetPasswordConfirmURL, values, {
    withCredentials: true,
  })
  return res.data
}

export const changePassword = async (
  data: ChangePasswordVariables
): Promise<AxiosResponse<any>> => {
  return postProtected("change_password/", data)
}

export const refreshToken = async (): Promise<any> => {
  // Refresh token is passed automatically in the cookie that was persisted after login
  const res = await wrappedAxios(
    "post",
    refreshTokenURL,
    {},
    { withCredentials: true }
  )
  return res.data
}

export const isAuthenticationError = (error: AxiosError): boolean => {
  return error?.response?.status === 401
}

// Takes a bark_api/ncapx_platform/ endpoint with a trailing slash and no leading slash.
const _requestProtected = async (
  endpoint: string,
  config: AxiosRequestConfig
): Promise<AxiosResponse<any>> => {
  const accessToken = useSessionStore.getAccessToken()
  let res: AxiosResponse<any>
  try {
    res = await axios.request({
      ...config,
      url: ncapxPlatformURL + endpoint,
      headers: { Authorization: `Bearer ${accessToken}`, ...config.headers },
    })
  } catch (error: any) {
    if (error.response?.data?.detail) {
      error.detail = error.response.data.detail
    } else if (error.response?.data?.error) {
      error.detail = error.response.data.error
    }
    throw error
  }
  return res
}

export const getProtected = (
  endpoint: string,
  params?: Record<string, unknown>
): Promise<AxiosResponse<any>> => {
  return _requestProtected(endpoint, { method: "get", params })
}

export const postProtected = (
  endpoint: string,
  data?: FormikValues
): Promise<AxiosResponse<any>> => {
  return _requestProtected(endpoint, { method: "post", data })
}

export const putProtected = (
  endpoint: string,
  data?: FormikValues
): Promise<AxiosResponse<any>> => {
  return _requestProtected(endpoint, { method: "put", data })
}

export const deleteProtected = (
  endpoint: string,
  data?: FormikValues
): Promise<AxiosResponse<any>> => {
  return _requestProtected(endpoint, { method: "delete", data })
}

export function isNCXUserLoggedIn(profile: { email: string }): boolean {
  return !!profile.email.match(/@(ncx|silviaterra).com$/)
}

export const establishMembership = async (
  accountId: string,
  token: string
): Promise<any> => {
  // Use a success-token query parameter after a redirect from Stripe payment
  // to immediately establish membership
  const accessToken = useSessionStore.getAccessToken()
  const res = await wrappedAxios(
    "post",
    `${ncapxPlatformURL}accounts/${accountId}/checkout-success/${token}`,
    {},
    { headers: { Authorization: `Bearer ${accessToken}` } }
  )
  return res.data
}

window.__superuser = {
  loginAs: async (email: string): Promise<void> => {
    try {
      // DEV: We assert inside `try/catch` to avoid spamming Sentry, https://app.asana.com/0/1199976942355619/1201348351661557/f
      assert(email, "Missing email to `loginAs`")
      const accessToken = useSessionStore.getAccessToken()
      await wrappedAxios(
        "post",
        loginAsURL,
        { email: email },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          withCredentials: true,
        }
      )
    } catch (err: any) {
      let errMessage: string = ""
      if (err.response) {
        errMessage =
          typeof err.response.data === "string"
            ? err.response.data
            : err.response.data.detail
      }
      errMessage = errMessage || err.message
      console.error(`Failed to login: ${errMessage || err.message}`)
      return
    }
    console.info("Successfully logged in! Refreshing now...")
    window.location.reload()
  },
  logout: (): void => {
    window.location.href = "/logout"
  },
}
