import { ReactNode, useContext, useEffect } from "react"
import {
  MutationStatus,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query"
import { useEffectOnce } from "react-use"

import { Toast } from "../components/Toast"
import { useAccessToken } from "../stores"
import { useProfile, useQueryParam, useVerifyEmail } from "../hooks"
import {
  VerifyEmailResponseTypes,
  VerifyUserEmailResponseContext,
} from "../context/VerifyUserEmailResponseContext"
import { AxiosError } from "axios"
import { Profile } from "../types"
import { useScrubQuerystring } from "../hooks/useScrubQuerystring"

interface VerifyEmailOuterContentProps {
  children: ReactNode
}

interface VerifyEmailInnerContentProps {
  profile: Profile
  children: ReactNode
}

export const VerifyEmailOuterContent = ({
  children,
}: VerifyEmailOuterContentProps) => {
  const queryClient = useQueryClient()
  const accessToken = useAccessToken()
  const isLoggedIn = !!accessToken

  const isUserConfirmingEmail = useQueryParam("confirm") === "email"
  // DEV: `key` is from the Django library we're using, naming variable `token` for clarity
  const confirmEmailToken = useQueryParam("key")
  const scrubQuerystring = useScrubQuerystring()

  const { data: profile } = useProfile(queryClient, {
    enabled: !!accessToken,
  }) as UseQueryResult<Profile>

  // DEV: If there's an error, `data` will be `undefined`. On success, it will be filled
  const {
    mutate: verifyEmail,
    data,
    error,
    status,
    isIdle,
    reset,
  } = useVerifyEmail(queryClient)

  const verifyEmailResponse: VerifyEmailResponseTypes = {
    data,
    error: error as AxiosError<unknown, any> | null,
    status,
    isIdle,
    reset,
  }

  // This used to be `useEffectOnce` but this effect is evaluated multiple times for users
  // who are logged out when they click the email verification link:
  //
  // 1. Landing at /?confirm=email&key=<verification token>, `isLoggedIn` === false
  // 2. Redirected to /login, `isLoggedIn` === false
  // 3. On receipt of access token (`isLoggedIn` changes), still at /login, `isLoggedIn` === true
  //
  // If a user clicked an email verification link, then `isUserConfirmingEmail` === true
  // and `confirmEmailToken` is populated, so the result of this dependency array
  // is that we call `verifyEmail` a single time, after `isLoggedIn` becomes true
  // (after #3 above)
  // https://app.asana.com/0/1198952737412966/1202733928483468/f
  useEffect(() => {
    if (
      isLoggedIn &&
      isUserConfirmingEmail &&
      confirmEmailToken &&
      profile?.is_email_verified !== true
    ) {
      verifyEmail({ key: confirmEmailToken })
      scrubQuerystring() // Scrubs `key=****` and `confirm=email`
    }
    // Including `verifyEmail` as a dependency here causes infinite re-runs of this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoggedIn,
    isUserConfirmingEmail,
    confirmEmailToken,
    profile?.is_email_verified,
  ])

  return (
    // DEV: We're mostly trusting that we only use MainLayout for the underlying one if we're logged in
    //   Otherwise, there's no surfacing of the `verifyEmailResponse`
    //   We require this structure so we can alter dismissable/not
    <VerifyUserEmailResponseContext value={verifyEmailResponse}>
      {children}
    </VerifyUserEmailResponseContext>
  )
}

// DEV: Using a separate component allows to wait until `profile` loaded to set `visible`
export const VerifyEmailInnerContent = ({
  profile,
  children,
}: VerifyEmailInnerContentProps) => {
  const verifyEmailResponse = useContext(VerifyUserEmailResponseContext)

  // Define default values to avoid conditional hook calls
  let verifyEmailStatus: MutationStatus = "idle"
  let verifyEmailIsIdle = true
  let resetVerifyEmailResponse = () => {}

  if (verifyEmailResponse) {
    verifyEmailStatus = verifyEmailResponse.status
    verifyEmailIsIdle = verifyEmailResponse.isIdle
    resetVerifyEmailResponse = verifyEmailResponse.reset
  }

  // Determine whether we should show a modal, toast, or nothing (regardless of dismiss)
  const isEmailVerified = profile?.is_email_verified
  const firedVerifyEmailRequest = !verifyEmailIsIdle
  let toastMessage: string | null = null

  // If we fired a verifyEmail request, the user is confirming their email
  if (firedVerifyEmailRequest) {
    if (verifyEmailStatus === "success") {
      toastMessage = "Your email address has been successfully verified!"
    } else if (verifyEmailStatus === "error") {
      // DEV: If an email verification token was already used, then we'll get `error`
      //   but the profile would still have `is_email_verified` set
      if (isEmailVerified) {
        toastMessage = "Your email address has been verified. Thank you!"
      }
    }
  }

  // DEV: We'll only run "verify email request" on page load, so `useEffectOnce` is perfect
  useEffectOnce(() => {
    if (toastMessage) {
      Toast.success(toastMessage)
    }

    // DEV: If we don't reset `verifyEmailResponse`, then we show Toast messages + "key expired" modals on page nav
    if (firedVerifyEmailRequest) {
      resetVerifyEmailResponse()
    }
  })

  return children
}
