import { useState, useEffect } from "react"

/*
  Example of usage in a component:
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1024px)")
*/

const useMediaQuery = (query: string): boolean => {
  const [isMatch, setIsMatch] = useState(window.matchMedia(query).matches)

  useEffect(() => {
    const mediaQuery = window.matchMedia(query)

    const handleChange = () => setIsMatch(mediaQuery.matches)

    mediaQuery.addEventListener("change", handleChange)

    return () => mediaQuery.removeEventListener("change", handleChange)
  }, [query])

  return isMatch
}

export default useMediaQuery
