import { Link } from "react-router"
import { useQueryClient } from "@tanstack/react-query"
import { Form, Formik } from "formik"
import { useDialogState } from "reakit/Dialog"
import { AxiosError } from "axios"
import * as Yup from "yup"

import { Modal } from "../components/Modal"
import { SubmitButton } from "../components/SubmitButton"
import { Toast } from "../components/Toast"
import { Checkbox2 } from "../components/Checkbox"
import { useUpdateProfile } from "../hooks"
import { genericErrMsg } from "../api/auth"
import { useSessionStore } from "../stores"
import { Profile } from "@/types"

interface AcceptTocModalTypes {
  profile: Profile | undefined
}

const validationSchema = Yup.object().shape({
  tou: Yup.bool().oneOf([true], "Terms of use are required"),
  pp: Yup.bool().oneOf([true], "Privacy policy is required"),
})

export const AcceptTocModal = ({ profile }: AcceptTocModalTypes) => {
  const { ncxLogoRef } = useSessionStore()
  const queryClient = useQueryClient()

  const acceptedTocDialog = useDialogState({
    animated: true,
    visible: profile?.is_email_verified && !profile?.accepted_toc,
  })

  const { mutateAsync: updateProfile } = useUpdateProfile(queryClient)

  const handleSubmit = async () => {
    await updateProfile(
      { accepted_toc: true },
      {
        onSuccess: () => {
          acceptedTocDialog.hide()
          Toast.success("You've accepted the terms.")
        },
        onError: (error) => {
          const axiosError = error as AxiosError
          Toast.error(axiosError?.message || genericErrMsg)
        },
      }
    )
  }

  return (
    <Modal
      aria-label="Accept TOC dialog"
      header="We've updated our Terms and Policies"
      dismissable={false}
      tabIndex={0}
      dialog={acceptedTocDialog}
      className="max-w-716 p-5"
      unstable_finalFocusRef={ncxLogoRef}
    >
      <p className="text-base leading-5.3 mb-4">
        NCX's Terms of Use and Privacy Policy has been updated to reflect our
        new project marketplace. To continue using the NCX Platform, you must
        read and accept the new Terms and Policies.
      </p>

      <Formik
        initialValues={{
          tou: false,
          pp: false,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form className="inline">
            <Checkbox2
              name="tou"
              label={
                <>
                  I have read and agree to the{" "}
                  <a
                    href="https://help.ncx.com/hc/en-us/articles/17747630067227-Terms-of-Use"
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Use
                  </a>
                </>
              }
              className="text-base leading-5.3 mb-4"
            />
            <Checkbox2
              name="pp"
              label={
                <>
                  I have read and agree to the{" "}
                  <a
                    href="https://help.ncx.com/hc/en-us/articles/17747353136923-Privacy-Policy"
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </>
              }
              className="text-base leading-5.3 mb-4"
            />

            <div className="flex flex-wrap flex-col-reverse sm:flex-row gap-4">
              <Link
                className="btn2 btn2-outline-primary w-full sm:w-auto text-center font-semibold px-7"
                to="/logout"
              >
                Cancel and Log Out
              </Link>

              <SubmitButton
                className="btn2 btn2-primary font-semibold w-full sm:w-auto"
                isSubmitting={formikProps.isSubmitting}
                disabled={!(formikProps.isValid && formikProps.dirty)}
              >
                Continue
              </SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default AcceptTocModal
