import { useState, useEffect } from "react"
import { Column } from "@tanstack/react-table"
import debounce from "lodash/debounce"
import {
  EligibleLeadColumnType,
  QualifiedLeadColumnType,
} from "../../../stores/usePartnersStore"
import { DataType } from "../PartnersTable"

interface TableFiltersInputTypes<TData extends DataType[]> {
  filter: QualifiedLeadColumnType | EligibleLeadColumnType
  currInput: Column<TData[number], unknown> | undefined
  wideSelectHideInput: any
  columnFiltersLength: any
}

const TableFiltersInput = <TData extends DataType[]>({
  filter,
  currInput,
  wideSelectHideInput,
  columnFiltersLength,
}: TableFiltersInputTypes<TData>) => {
  const [value, setValue] = useState<
    string | number | readonly string[] | undefined
  >(
    (currInput?.getFilterValue() as
      | string
      | number
      | readonly string[]
      | undefined) || ""
  )
  const debounceOnChange = currInput && debounce(currInput?.setFilterValue, 250)

  useEffect(() => {
    setValue(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currInput?.columnDef.filterFn])

  useEffect(() => {
    if (columnFiltersLength === 0) {
      setValue("")
    }
  }, [columnFiltersLength])

  if (wideSelectHideInput) {
    return null
  }

  if (filter === "interest_date") {
    return (
      <input
        type="date"
        id={`input-${filter}`}
        name={`input-${filter}`}
        className="form-input st-input col-start-3 col-end-4"
        value={
          (currInput?.getFilterValue() as
            | string
            | number
            | readonly string[]
            | undefined) || ""
        }
        onChange={(e) => currInput?.setFilterValue(e.target.value)}
      />
    )
  }

  return (
    <input
      id={`input-${filter}`}
      name={`input-${filter}`}
      className="form-input st-input col-start-3 col-end-4"
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value)
        debounceOnChange && debounceOnChange(e.target.value)
      }}
      autoComplete="off"
      disabled={!currInput}
    />
  )
}

export default TableFiltersInput
