import { useState, useMemo } from "react"
import {
  ColumnFiltersState,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table"
import { QueryStatus } from "@tanstack/react-query"
import { DialogStateReturn } from "reakit/Dialog"

import NothingFound from "../../components/NothingFound"
import PartnersAccount from "./PartnersAccount"
import PartnersAccountMap from "./PartnersAccountMap"
import PartnersTable, { BaseColumn } from "./PartnersTable"
import {
  qualifiedTableColumns,
  QualifiedTableColumnType,
} from "./PartnersTable/helpers"
import {
  usePartnersStore,
  QualifiedLeadColumnType,
  QualifiedLeadFilterFunctionsTypes,
} from "../../stores/usePartnersStore"
import { QUALIFIED_LEADS } from "../../shared/constants"
import { PartnersLandownerTypes, RowsType, RowType } from "@/types/partners"
import { Profile } from "@/types"

interface PartnersQualifiedLeadsTypes {
  partnerDashboardData: RowsType | undefined
  profile: Profile | undefined
  inviteDealOwnerDialog: DialogStateReturn
  editDealOwnerDialog: DialogStateReturn
  account: PartnersLandownerTypes | undefined
  accountStatus: QueryStatus
}

type ColumnType = BaseColumn & QualifiedTableColumnType

const PartnersQualifiedLeads = ({
  partnerDashboardData,
  profile,
  inviteDealOwnerDialog,
  editDealOwnerDialog,
  account,
  accountStatus,
}: PartnersQualifiedLeadsTypes) => {
  const {
    activeTab,
    qualifiedLeadSorting,
    qualifiedLeadColumnFilters,
    qualifiedLeadFilters,
    qualifiedLeadFilterFunctions,
    setDealOwnerRowId,
    setActiveRow,
    setViewport,
    setQualifiedLeadFilterFunctions,
    setQualifiedLeadColumnFilters,
    setQualifiedLeadFilters,
    setQualifiedLeadSorting,
  } = usePartnersStore()

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const columns = useMemo(
    () =>
      qualifiedTableColumns(
        qualifiedLeadFilterFunctions,
        inviteDealOwnerDialog,
        editDealOwnerDialog,
        setDealOwnerRowId,
        profile
      ),
    [
      qualifiedLeadFilterFunctions,
      inviteDealOwnerDialog,
      editDealOwnerDialog,
      setDealOwnerRowId,
      profile,
    ]
  )

  if (activeTab === QUALIFIED_LEADS && partnerDashboardData?.length === 0) {
    return (
      <NothingFound
        title="No qualified leads found"
        text="There are no qualified leads associated with this partner account yet."
      />
    )
  }

  if (activeTab !== QUALIFIED_LEADS) {
    return null
  }

  return (
    <div className="grid grid-cols-1 md:gap-6 md:grid-cols-3">
      <div className="col-span-2 partners-table qualified-leads">
        <PartnersTable<RowsType, ColumnType[]>
          data={partnerDashboardData}
          onRowClick={(row: RowType) => {
            setActiveRow(row)
            setViewport(null)
          }}
          filterFunctions={qualifiedLeadFilterFunctions}
          setFilterFunctions={(val: QualifiedLeadFilterFunctionsTypes) => {
            setQualifiedLeadFilterFunctions(val)
          }}
          columnFilters={qualifiedLeadColumnFilters}
          setColumnFilters={(val: ColumnFiltersState) => {
            setQualifiedLeadColumnFilters(val)
          }}
          filters={qualifiedLeadFilters}
          setFilters={(val: QualifiedLeadColumnType[]) =>
            setQualifiedLeadFilters(val)
          }
          columns={columns as ColumnType[]}
          sorting={qualifiedLeadSorting}
          setSorting={(val: SortingState) => setQualifiedLeadSorting(val)}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        />

        <PartnersAccountMap
          bounds={account?.bounds as string}
          accountStatus={accountStatus}
        />
      </div>

      <PartnersAccount
        account={account}
        accountStatus={accountStatus}
        selectedRows={rowSelection}
        setRowSelection={setRowSelection}
      />
    </div>
  )
}

export default PartnersQualifiedLeads
