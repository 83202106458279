import { useCallback } from "react"
import { useLocation, useNavigate } from "react-router"

export const useScrubQuerystring = () => {
  // DEV: We had a param-specific scrub version but we don't actively use it
  //   Feel free to restore it from https://github.com/ncx-co/ncapx-platform/blob/57939e95a5c86df2407f9ea2d76c883a4946ad54/src/shared/utils.js#L141-L165
  //   though we might want multi-param support as well
  // DEV: We scrub to prevent leaking params via HTTP referer, https://github.com/django/django/blob/8a7ac78b706797a03d26b88eddb9d1067ed35b66/django/contrib/auth/views.py#L280-L288
  const navigate = useNavigate()
  const location = useLocation()

  return useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    navigate(location.pathname, { ...location.state })
  }, [navigate, location])
}
