import { Form, Formik, FormikValues } from "formik"
import { QueryStatus, useQueryClient } from "@tanstack/react-query"
import { RowSelectionState } from "@tanstack/react-table"

import { WrappedInput } from "../../components/Input"
import { SubmitButton } from "../../components/SubmitButton"
import { Toast } from "../../components/Toast"
import { Spinner } from "../../components/Spinner"
import { usePartnersBulkUpdateLandownerStatus } from "../../hooks"
import { genericErrMsg } from "../../api/auth"
import { usePartnersStore } from "../../stores/usePartnersStore"
import { PARTNERS_ACCOUNT_STATUSES } from "../../shared/constants"
import { heapTrackEvent } from "../../api/integrations"
import { AxiosError } from "axios"

interface PartnerAccountUpdateStatusTypes {
  accountStatus: QueryStatus
  selectedRows: RowSelectionState | undefined
  onUpdateSuccess: () => void
}

const PartnerAccountUpdateStatus = ({
  accountStatus,
  selectedRows,
  onUpdateSuccess,
}: PartnerAccountUpdateStatusTypes) => {
  const queryClient = useQueryClient()
  const { activeRow } = usePartnersStore()

  const { mutateAsync: bulkUpdateLandownerStatus } =
    usePartnersBulkUpdateLandownerStatus(queryClient, {
      onSuccess: () => {
        Toast.success("Statuses have been updated successfully.")
        onUpdateSuccess && onUpdateSuccess()
      },
      onError: (error: AxiosError) => {
        Toast.error(error?.message || genericErrMsg)
      },
    })

  const handleUpdateStatus = async (selectAction: FormikValues) => {
    let selectedApIds = Object.keys(selectedRows as RowSelectionState).map(
      (apId) => parseInt(apId)
    )

    if (!selectedApIds.length && activeRow) {
      selectedApIds = [activeRow.id]
    }

    heapTrackEvent("widget-status-update", {
      number_of_rows: selectedApIds.length,
    })

    await bulkUpdateLandownerStatus({
      aps: selectedApIds,
      status: selectAction.status,
    })
  }

  if (accountStatus === "pending") {
    return (
      <div className="w-full card card-content self-start">
        <p className="label font-bold text-overline text-dusk-500 mb-3">
          Update Status
        </p>

        <div className="flex justify-center items-center h-24">
          <Spinner />
        </div>
      </div>
    )
  }

  return (
    <div className="w-full card card-content self-start">
      <Formik
        initialValues={{
          status:
            PARTNERS_ACCOUNT_STATUSES.find(
              (accStatus) => accStatus.label === activeRow?.status
            )?.value || "",
        }}
        onSubmit={handleUpdateStatus}
        enableReinitialize
      >
        {(formikProps) => (
          <Form>
            <WrappedInput
              label="Update Status"
              labelClass="text-overline text-dusk-500 mb-3"
              aria-label="Update Status"
              name="status"
              as="select"
              placeholder="Select a status"
              options={PARTNERS_ACCOUNT_STATUSES}
              className="resize-none border-charcoal-50 mb-4"
            />

            <SubmitButton
              className="btn2 btn2-primary btn2-block font-semibold widget-status-update"
              isSubmitting={formikProps.isSubmitting}
              disabled={!(formikProps.isValid && formikProps.dirty)}
            >
              Update Status
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default PartnerAccountUpdateStatus
