import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query"

import { getPartnerTableRowParcelAssessment } from "../../../api/data"

export const useGetPartnerTableRowParcelAssessment = <
  TData,
  TError,
  TValues extends number,
>(
  config: UseMutationOptions<TData, TError, TValues> = {}
): UseMutationResult<TData, TError, TValues, unknown> => {
  return useMutation({
    mutationFn: (apId: number) =>
      getPartnerTableRowParcelAssessment(apId.toString()),
    ...config,
  })
}
