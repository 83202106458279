import { ReactNode } from "react"
import cx from "classnames"

import { PROJECT_BADGE_TYPES } from "../shared/constants"
import { ProjectBadgeType } from "../types/constants"

interface ProjectBadgeAnimationTypes {
  type: ProjectBadgeType
}

interface ProjectBadgeTypes {
  type: ProjectBadgeType
  size?: "sm" | "lg"
  animated?: boolean
  block?: boolean
  children: ReactNode
}

const ProjectBadgeAnimation = ({ type }: ProjectBadgeAnimationTypes) => (
  <div className="project-badge-animation">
    <div
      className={cx(
        "project-badge-animation-a",
        {
          "bg-grass": type === PROJECT_BADGE_TYPES.primary,
        },
        {
          "bg-fire-700": type === PROJECT_BADGE_TYPES.error,
        }
      )}
    />
    <div
      className={cx(
        "project-badge-animation-b",
        {
          "bg-grass": type === PROJECT_BADGE_TYPES.primary,
        },
        {
          "bg-fire-700": type === PROJECT_BADGE_TYPES.error,
        }
      )}
    />
  </div>
)

export const ProjectBadge = ({
  type = "neutral",
  size = "lg",
  animated = false,
  block = false,
  children,
}: ProjectBadgeTypes) => {
  const classNames = cx(
    "relative shrink-0 text-sm font-bold rounded-full border border-solid",
    { "px-4 py-2": size === "lg" },
    { "leading-5 px-3 py-1": size === "sm" },
    { "border-dusk-900 bg-dusk-50 text-dusk-900": type === "neutral" },
    { "border-grass-900 bg-grass-100 text-grass-900": type === "primary" },
    { "border-orange-900 bg-[#F8F1E0] text-orange-900": type === "info" },
    { "border-orange-900 bg-orange-50 text-orange-900": type === "warning" },
    { "border-fire-900 bg-fire-50 text-fire-900": type === "error" },
    block ? "block" : "inline-block"
  )

  return (
    <div
      className={cx("relative text-center", block ? "block" : "inline-block")}
    >
      {animated ? <ProjectBadgeAnimation type={type} /> : null}
      <span className={classNames}>{children}</span>
    </div>
  )
}
