import { QueryClient, UseMutationResult } from "@tanstack/react-query"

import { useMutationWithRefresh } from "../utils"
import { getPartnerTableRowTileLinks } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"

export const useGetPartnerTableRowTileUrls = <
  TData,
  TError,
  TVariables extends number,
>(
  queryClient: QueryClient,
  apId: number,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> => {
  return useMutationWithRefresh<TData, TError, TVariables, unknown>(
    queryClient,
    (apId: number) => getPartnerTableRowTileLinks(apId.toString()),
    {
      _onSuccess: (data) => {
        queryClient.setQueryData(
          ["partners", "ncapi", "tile", "links", "ap", apId],
          data
        )
      },
      ...config,
    }
  )
}
