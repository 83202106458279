import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { useSessionStore } from "../../../stores"
import { useMutationWithRefresh } from "../utils"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"
import { logout } from "../../../api/auth"
import { AxiosResponse } from "axios"

type LogoutResponse = AxiosResponse<any>

export function useLogout<TError, TVariables>(
  queryClient: QueryClient,
  config: UseMutationWithRefreshConfig<LogoutResponse, TError, TVariables> = {}
): UseMutationResult<LogoutResponse, TError, TVariables, unknown> {
  const {
    setAuthData,
    clearInaccessibleAccountIds,
    clearAccountValueOverrides,
  } = useSessionStore((state) => state)

  return useMutationWithRefresh(queryClient, logout, {
    ...config,
    onSuccess: function (data: LogoutResponse, ...args: [TVariables, unknown]) {
      setAuthData(null)
      clearInaccessibleAccountIds()
      clearAccountValueOverrides()
      queryClient.clear()
      if (config.onSuccess) {
        config.onSuccess.call(this, data, ...args)
      }
    },
  })
}
