import { QueryStatus } from "@tanstack/react-query"

import { Profile } from "@/types"
import { Spinner } from "../../components/Spinner"

interface PartnersAccountProfileTypes {
  profiles: Profile[] | undefined
  accountStatus: QueryStatus
}

const PartnersAccountProfile = ({
  profiles,
  accountStatus,
}: PartnersAccountProfileTypes) => {
  if (accountStatus === "pending") {
    return (
      <div className="relative w-full card card-content flex flex-col gap-5 self-start overflow-auto max-h-[366px]">
        <h4 className="sticky -top-4 left-0 right-0 bg-cloud-50 text-overline text-dusk-500 p-4 -m-4 mb-0 border-b-1 border-b-cloud z-2">
          All Contact Info
        </h4>

        <div className="flex justify-center items-center h-[130px]">
          <Spinner />
        </div>
      </div>
    )
  }

  return (
    <div className="relative w-full card card-content flex flex-col gap-5 self-start overflow-auto max-h-[366px]">
      <h4 className="sticky -top-4 left-0 right-0 bg-cloud-50 text-overline text-dusk-500 p-4 -m-4 mb-0 border-b-1 border-b-cloud z-2">
        All Contact Info
      </h4>

      {profiles?.map((profile) => (
        <div className="card card-content-simple" key={profile.id}>
          <ul>
            <li>
              <span className="font-semibold">First name - </span>
              {profile?.first_name}
            </li>
            <li>
              <span className="font-semibold">Last name - </span>
              {profile?.last_name}
            </li>
            <li>
              <span className="font-semibold">Email - </span>
              {profile?.email ? (
                <a href={`mailto:${profile?.email}`}>{profile?.email}</a>
              ) : null}
            </li>
            <li>
              <span className="font-semibold">Phone Number - </span>
              {profile?.phone_number}
            </li>
          </ul>
        </div>
      ))}
    </div>
  )
}

export default PartnersAccountProfile
