import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { FormikValues } from "formik"

import { useMutationWithRefresh } from "../utils"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"
import { unlockDisqualifyAccountProjectReachout } from "../../../api/data"
import { ELIGIBLE_LEADS } from "../../../shared/constants"
import { EligibleLeadsTypes, EligibleLeadTypes } from "../../../types/partners"

export const useUnlockDisqualifyAccountProjectReachout = <
  TData extends EligibleLeadTypes,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  apId: number,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> => {
  return useMutationWithRefresh<TData, TError, TVariables, unknown>(
    queryClient,
    (values) => unlockDisqualifyAccountProjectReachout(apId.toString(), values),
    {
      _onSuccess: (data: EligibleLeadTypes | Record<string, never>) => {
        queryClient.setQueryData(
          ["partners", ELIGIBLE_LEADS],
          (oldData: EligibleLeadsTypes) => {
            if ("id" in data) {
              return {
                ...oldData,
                results: oldData.results.map((landowner: EligibleLeadTypes) => {
                  if (landowner.id === data.id) {
                    return data
                  }
                  return landowner
                }),
              }
            }

            return {
              ...oldData,
              results: oldData.results.filter(
                (landowner: EligibleLeadTypes) => landowner.id !== apId
              ),
            }
          }
        )
      },
      ...config,
    }
  )
}
