import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { Link } from "react-router"
import cx from "classnames"

import { bellIcon } from "../../shared/icons"
import NavBarInboxLinkIndicator from "./NavBarInboxLinkIndicator"

interface NavBarInboxLinkTypes {
  isPartnersNav: boolean
  isMultiAccount: boolean
  accountId: string
  className?: string
  onClick: () => void
  showLinks: boolean
}

const NavBarInboxLink = ({
  isPartnersNav,
  isMultiAccount,
  accountId,
  className,
  onClick,
  showLinks,
}: NavBarInboxLinkTypes) => {
  if (
    !showLinks ||
    isPartnersNav ||
    (isMultiAccount && accountId === "_single")
  ) {
    return null
  }

  return (
    <Link
      to={isMultiAccount ? `/accounts/${accountId}/inbox` : "/inbox"}
      className={cx(
        "h-16 flex flex-row text-white items-center font-semibold hover:bg-nav-active px-3",
        className
      )}
      onClick={onClick}
    >
      <span className="relative flex items-center">
        <FontAwesomeIcon icon={bellIcon as IconProp} size="lg" />

        <NavBarInboxLinkIndicator />
      </span>
    </Link>
  )
}

export default NavBarInboxLink
