import { Dispatch, SetStateAction, useState } from "react"
import { useNavigate, useLocation } from "react-router"
import { Formik } from "formik"
import * as Yup from "yup"

import { genericErrMsg } from "../../api/auth"
import { AUTH_STEPS } from "./constants"
import { useCheckUserByEmail } from "../../hooks"
import { AuthDataTypes } from "@/types/auth"
import GettingStartedFormComponent from "./GettingStartedFormComponent"

export interface GettingStartedFormValues {
  email: string
}

interface GettingStartedFormTypes {
  inputEmail: string
  setInputEmail: Dispatch<SetStateAction<string>>
  setAuthData: Dispatch<SetStateAction<GettingStartedFormValues>>
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please provide a valid email address.")
    .required("Please provide your email address."),
})

const GettingStartedForm = ({
  inputEmail,
  setInputEmail,
  setAuthData,
}: GettingStartedFormTypes) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const navigate = useNavigate()
  const location = useLocation()

  const { mutateAsync: checkUserByEmail } = useCheckUserByEmail<
    any,
    Error,
    AuthDataTypes
  >({
    onSuccess: (
      data: { exists: any; sso_provider: any },
      variables: {
        email?: SetStateAction<string>
      }
    ) => {
      setInputEmail(variables.email as SetStateAction<string>)
      let nextStep
      if (data.exists) {
        nextStep = AUTH_STEPS.emailLogin
      } else {
        nextStep = AUTH_STEPS.signUp
      }
      navigate(location.pathname, {
        state: { ...location.state, authStep: nextStep },
      })
    },
    onError: (error: Error) => {
      setErrorMessage(error?.message || genericErrMsg)
    },
  })

  const onSubmitEmail = async (_values: GettingStartedFormValues) => {
    const values = { ..._values }
    await checkUserByEmail(values)
  }

  return (
    <Formik<GettingStartedFormValues>
      initialValues={{
        email: inputEmail || "",
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmitEmail}
    >
      {(formikProps) => (
        <GettingStartedFormComponent
          {...formikProps}
          setAuthData={setAuthData}
          errorMessage={errorMessage}
        />
      )}
    </Formik>
  )
}
export default GettingStartedForm
