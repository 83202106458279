import { ReactNode } from "react"
import cx from "classnames"

interface CardProps {
  className?: string
  children: ReactNode
}

interface IconCardProps extends CardProps {
  icon: ReactNode
  dataTest?: string
}

interface ContentCardProps extends CardProps {
  roundedMd?: boolean
}

export const IconCard = ({
  className,
  icon,
  dataTest,
  children,
}: IconCardProps) => (
  <div className={cx("space-y-4", className)} data-test={dataTest}>
    <div className="media space-x-4">
      <div className="media-figure">{icon}</div>
      <div className="media-body leading-5">{children}</div>
    </div>
  </div>
)

export const ErrorCard = ({ className, children }: CardProps) => (
  <IconCard
    className={cx("card-error", className)}
    icon={<i className="far fa-times-circle" />}
    dataTest="error-card"
  >
    {children}
  </IconCard>
)

export const SuccessCard = ({ className, children }: CardProps) => (
  <IconCard
    className={cx("card-success", className)}
    icon={<i className="far fa-check-circle" />}
    dataTest="success-card"
  >
    {children}
  </IconCard>
)

export const ContentCard = ({
  className,
  roundedMd = false,
  children,
}: ContentCardProps) => (
  <div
    className={cx("bg-white", roundedMd ? "rounded-md" : "rounded", className)}
    data-test="content-card"
  >
    <div className="p-4 md:px-12 md:py-6">{children}</div>
  </div>
)
