import { CSSProperties, ReactElement } from "react"
import assert from "assert"
import cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons"

interface SubmitButtonProps {
  className?: string
  children: ReactElement | string
  disabled?: boolean
  isSubmitting: boolean
  typeButton?: boolean
  style?: CSSProperties
  onClick?: () => void
}

// Intended as replacement for our `Button` component which assumes too much
// DEV: Using `isSubmitting` to match `Formik`
export const SubmitButton = ({
  className,
  children,
  disabled,
  isSubmitting,
  typeButton,
  onClick,
  ...rest
}: SubmitButtonProps) => {
  // If we are submitting, then verify we don't also have `disabled=true`
  let ignoreDisabledClassName
  let derivedDisabled
  if (isSubmitting === true) {
    assert.notEqual(
      disabled,
      true,
      "Not permitted to have `disabled=true` and `isSubmitting=true`. " +
        "This is a forbidden visual state (i.e. grey + spinner). " +
        "Please only set `disabled=true` when the form is not yet ready to submit. " +
        "`isSubmitting=true` will set `disabled` properly otherwise. " +
        "Use the same root condition for `disabled` and `isSubmitting` in a mutually-exclusive fashion, " +
        "as learned in https://app.asana.com/0/1199976942355619/1202722960327545/f"
    )
    derivedDisabled = true
    ignoreDisabledClassName = true
  } else {
    // Otherwise (submitting is false or undefined), use disabled as per normal
    derivedDisabled = disabled
    ignoreDisabledClassName = false
  }

  // Perform normal render
  return (
    <button
      type={typeButton ? "button" : "submit"}
      disabled={derivedDisabled}
      className={cx(
        {
          "ignore-disabled": ignoreDisabledClassName,
        },
        className
      )}
      onClick={onClick}
      {...rest}
    >
      <span className="flex items-center justify-center whitespace-nowrap">
        <span className="flex-auto flex justify-center items-center">
          {children}
          {isSubmitting && (
            // DEV: Use same element as {children} to properly center in `.btn-block`
            <>
              &nbsp;
              {/* DEV: <span> ensures no weird alignment issues when swapped with SVG */}
              <span>
                {/* DEV: Icon doesn't always load via kit in UrlForm submit (e.g. selling process in Firefox) */}
                {/*   As a result, use preloded SVG version */}
                <FontAwesomeIcon icon={faSpinnerThird} className="fa-spin" />
              </span>
            </>
          )}
        </span>
      </span>
    </button>
  )
}
