import { Column } from "@tanstack/react-table"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus } from "@fortawesome/pro-solid-svg-icons"

import {
  EligibleLeadColumnType,
  QualifiedLeadColumnType,
} from "../../../stores/usePartnersStore"
import { DataType } from "../PartnersTable"

interface TableFiltersRemoveFilterTypes<TData extends DataType[]> {
  filter: QualifiedLeadColumnType | EligibleLeadColumnType
  filters: QualifiedLeadColumnType[] | EligibleLeadColumnType[]
  currInput: Column<TData[number], unknown> | undefined
  setFilters: (
    val: QualifiedLeadColumnType[] & EligibleLeadColumnType[]
  ) => void
}

const TableFiltersRemoveFilter = <TData extends DataType[]>({
  filter,
  filters,
  currInput,
  setFilters,
}: TableFiltersRemoveFilterTypes<TData>) => (
  <button
    type="button"
    className="w-5 h-5 transition-colors duration-200 rounded hover:bg-cloud"
    onClick={() => {
      currInput?.setFilterValue("")
      setFilters(
        filters.filter(
          (prevFilter) => prevFilter !== filter
        ) as QualifiedLeadColumnType[] & EligibleLeadColumnType[]
      )
    }}
  >
    <FontAwesomeIcon icon={faMinus} />
  </button>
)

export default TableFiltersRemoveFilter
