import { QueryClient, UseQueryResult } from "@tanstack/react-query"
import { ColumnFiltersState, SortingState } from "@tanstack/react-table"

import { useQueryWithRefresh } from "../utils"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"
import { ELIGIBLE_LEADS } from "../../../shared/constants"
import { getProjectReachoutDashboardView } from "../../../api/data"
import { EligibleLeadFilterFunctionsTypes } from "../../../stores/usePartnersStore"

export const useProjectReachoutDashboardView = <TData, TError>(
  queryClient: QueryClient,
  sorting: SortingState,
  columnFilters: ColumnFiltersState,
  filterFunctions: EligibleLeadFilterFunctionsTypes,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> => {
  return useQueryWithRefresh<TData, TError>(
    queryClient,
    ["partners", ELIGIBLE_LEADS],
    () =>
      getProjectReachoutDashboardView(sorting, columnFilters, filterFunctions),
    config
  )
}
