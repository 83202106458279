import { useQueryClient } from "@tanstack/react-query"

import { NOTIFICATIONS } from "../shared/constants"
import { useAccountId, useAccountNotifications, useIsMultiAccount } from "./"
import { useAccessToken } from "../stores"
import { NotificationTypes } from "@/types/notifications"

const useHasNewMessages = (): boolean => {
  const queryClient = useQueryClient()
  const accountId = useAccountId()
  const isMultiAccount = useIsMultiAccount()
  const accessToken = useAccessToken()

  const isLoggedIn = !!accessToken

  const queryEnabled =
    isLoggedIn &&
    ((isMultiAccount && accountId !== "_single") || !isMultiAccount)

  const { data: notifications } = useAccountNotifications<
    NotificationTypes[],
    Error
  >(queryClient, accountId, {
    enabled: queryEnabled,
  })

  const newMessages =
    (
      notifications?.filter(
        (notification) =>
          (notification.key === NOTIFICATIONS.pd_invite ||
            notification.key === NOTIFICATIONS.change_detected) &&
          (notification?.viewed_at === null ||
            notification?.viewed_at === undefined)
      ) ?? []
    ).length > 0

  return newMessages
}

export default useHasNewMessages
