interface SpinnerTypes {
  loadingText?: string
}

export const Spinner = ({ loadingText = "Loading..." }: SpinnerTypes) => {
  return (
    <div className="flex items-center space-x-2">
      <i className="fas fa-lg fa-circle-notch fa-spin text-green-500"></i>
      <p className="text-gray-700 text-overline">{loadingText}</p>
    </div>
  )
}
