import assert from "assert"
// @ts-ignore:
import sha512 from "sha512"
import { area } from "@turf/turf"
import { union } from "@turf/union"
import {
  feature as turfFeature,
  featureCollection,
  Feature,
  Geometry,
  Properties,
  FeatureCollection,
  Polygon,
  MultiPolygon,
} from "@turf/helpers"
import { parse as parseWKT } from "wellknown"

import { ACRES_PER_SQUARE_METER } from "../shared/constants"
import { ParcelReturnData } from "@/api/data"
import { FeatureType } from "@/types"

const SHORTEN_UNITS = ["", "k", "M", "B"]
const SHORTEN_ACREAGE_SIGNIFICANT_DIGITS = 3
// DEV: We historically had `undefined`, which used browser's locale, https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString#using_options
//   However, in MS Edge this yields "US$12" instead of "$12" for currency which broke a defensive precision `assert`
//   https://app.asana.com/0/1199976942355619/1202156656602316/f
//   Now all locales use this for consistency
const FORCED_LOCALE = "en-US"

// DEV: Would accept significant digits parameter but 1k-9k breaks the mold
export function shortenAcreage(num: number) {
  const locale = FORCED_LOCALE
  if (num < 0) {
    throw new Error("Negative numbers not supported")
  }

  // If we're in known cases, then handle them directly
  if (num === 0) {
    return num.toLocaleString(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  } else if (num < 1) {
    return num.toLocaleString(locale, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    })
  } else if (num < 10000) {
    return num.toLocaleString(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  } else {
    // Otherwise, calculate our exponent and units index
    // https://github.com/sindresorhus/pretty-bytes/blob/cdb25d5db75dcc442b747325c5ae0e85875831aa/index.js#L105
    // 10,000 -> 10^4 -> 4
    const exponent = Math.floor(Math.log10(num))
    // 4 -> 1 (signifying thousands)
    const unitsIndex = Math.floor(exponent / 3)

    // Find truncation point and truncate number
    num = num / Math.pow(10, unitsIndex * 3)
    let decimalPlaces = SHORTEN_ACREAGE_SIGNIFICANT_DIGITS - 1 - (exponent % 3)

    decimalPlaces = Math.max(0, Math.min(decimalPlaces, 20))

    // Find our unit
    if (unitsIndex > SHORTEN_UNITS.length) {
      throw new Error(`Number too large, not yet supported: ${num}`)
    }
    const unit = SHORTEN_UNITS[unitsIndex]

    return (
      num.toLocaleString(locale, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      }) + unit
    )
  }
}

export function formatCurrency(value: number | null, showCents = false) {
  const locale = FORCED_LOCALE

  const decimalPlaces = showCents ? 2 : 0

  // DEV: It's a little risky to switch from `int` to `float`/`double`
  //   but JS operates entirely in doubles and unavoidable for native formatting (i.e. need extra library)
  // DEV: Apparently there's no good test for int/int so we're good, https://ellenaua.medium.com/floating-point-errors-in-javascript-node-js-21aadd897bf8
  return value?.toLocaleString(locale, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    currency: "USD",
    style: "currency",
  })
}

// https://stackoverflow.com/a/43890733/1960509
export function roundTo(num: number, interval: number) {
  return Math.round(num / interval) * interval
}

let WKT_ARR_TO_FEATURES_COUNTER = 0
export function wktArrToFeatures(wktArr: ParcelReturnData[]): FeatureType[] {
  const counter = WKT_ARR_TO_FEATURES_COUNTER
  WKT_ARR_TO_FEATURES_COUNTER += 1

  const geojsonArr = wktArr.map((wkt) => {
    return {
      parsedGeometry: parseWKT(wkt.geometry), // Use a colon (:) here
      parcelId: wkt.parcel_id, // Use a colon (:) here as well
    }
  })

  // Convert geometry to features, filtering out anything that's not
  // a Polygon or MultiPolygon
  const features = geojsonArr
    .map((geojsonData, i) => {
      const wktHash = sha512(wktArr[i].geometry).toString("hex")
      const geojson = geojsonData.parsedGeometry
      const parcelId = geojsonData.parcelId
      if (geojson?.type === "Polygon") {
        return turfFeature(
          {
            type: "Polygon",
            coordinates: geojson.coordinates,
          },
          { parcelId: parcelId }, // properties
          // DEV: Deterministic ids by hash work well until someone edits a shape and re-adds the parcel
          //   then we have multiple
          //   Steps to reproduce:
          //   1. Add a parcel via parcel select
          //   2. Move the parcel to another location
          //   3. Re-add the parcel to the map via parcel select
          //   4. Return to normal map and see only 1 of the features is visible due to same id
          { id: `wkt-${wktHash}-${counter}` }
        )
      } else if (geojson?.type === "MultiPolygon") {
        return geojson.coordinates.map((coords, j) => {
          return turfFeature(
            { type: "Polygon", coordinates: coords },
            { parcelId: parcelId }, // properties
            { id: `wkt-${wktHash}-${counter}-${j}` }
          )
        })
      }
    })
    .flat()
    .filter((feature) => feature)
  return features as FeatureType[]
}

export function convertMultiPolygonToPolygons(feature: FeatureType) {
  if (feature.geometry.type === "MultiPolygon") {
    return feature.geometry.coordinates.map((coordinateGroup, index) =>
      turfFeature(
        { type: "Polygon", coordinates: coordinateGroup },
        {}, // properties
        { id: `${feature.id || "feature"}-${index}` }
      )
    )
  }

  return [feature as Feature<Geometry, Properties>]
}

export const makeCompositeKey = (arr: [string, string]) => arr.join("+")

export const assertAccountId = (accountId: string) => {
  // DEV: Numeric account ids *can* work here but will cause issues within React-Query hooks
  //   To keep types consistent (and definitely work for magic "_single"), we're enforce strings for accountId
  assert(accountId)
  assert.strictEqual(typeof accountId, "string")
}

export function calcAcres(geoJSON: FeatureType[]) {
  // if undefined, return 0
  if (geoJSON === undefined || geoJSON.length === 0) {
    return 0
  }
  let preppedGeoJSON
  if (geoJSON.length > 1) {
    preppedGeoJSON = union(
      featureCollection(geoJSON) as FeatureCollection<Polygon | MultiPolygon>
    ) as FeatureType
  } else {
    preppedGeoJSON = geoJSON[0]
  }
  return area(preppedGeoJSON) * ACRES_PER_SQUARE_METER
}
