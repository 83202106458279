import { create } from "zustand"
import { PAYMENT_TYPES } from "../shared/constants"
import { ProgramCategoryType } from "../types/constants"
import { PaymentTypeKeysType } from "../types/program"

export interface StateTypes {
  category: ProgramCategoryType | null
  govtProjectsOn: boolean
  ineligibleProjectsOn: boolean
  hasLandownerCost: boolean
  termLengthFilter: [number, number]
  paymentTypes: Record<PaymentTypeKeysType, boolean>
  searchProjects: string
}

export const initialState: StateTypes = {
  category: null,
  govtProjectsOn: true,
  ineligibleProjectsOn: false,
  hasLandownerCost: true,
  termLengthFilter: [0, 100],
  paymentTypes: Object.keys(PAYMENT_TYPES).reduce(
    (acc, paymentType) => {
      return { ...acc, [paymentType]: false }
    },
    {} as Record<string, boolean>
  ),
  searchProjects: "",
}

export const useProjectsStore = create<{
  category: ProgramCategoryType | null
  govtProjectsOn: boolean
  ineligibleProjectsOn: boolean
  hasLandownerCost: boolean
  termLengthFilter: [number, number]
  paymentTypes: Record<PaymentTypeKeysType, boolean>
  searchProjects: string

  setCategory: (category: ProgramCategoryType | null) => void
  setGovtProjectsOn: (govtProjectsOn: boolean) => void
  setIneligibleProjectsOn: (ineligibleProjectsOn: boolean) => void
  setHasLandownerCost: (hasLandownerCost: boolean) => void
  setTermLengthFilter: (filter: [number, number]) => void
  setPaymentTypes: (paymentTypes: Record<PaymentTypeKeysType, boolean>) => void
  setSearchProjects: (searchProjects: string) => void
  setMultiState: (newState: Partial<StateTypes>) => void
}>((set) => ({
  // Initial values for each piece of state
  ...initialState,

  // Actions
  setCategory: (category) => set({ category }),
  setGovtProjectsOn: (govtProjectsOn) => set({ govtProjectsOn }),
  setIneligibleProjectsOn: (ineligibleProjectsOn) =>
    set({ ineligibleProjectsOn }),
  setHasLandownerCost: (hasLandownerCost) => set({ hasLandownerCost }),
  setTermLengthFilter: (filter) => set({ termLengthFilter: filter }),
  setPaymentTypes: (paymentTypes) => set({ paymentTypes }),
  setSearchProjects: (searchProjects) => set({ searchProjects }),

  // Set multiple properties at once
  setMultiState: (newState) => set(newState),
}))
