import { AxiosError } from "axios"
import { useQueryClient } from "@tanstack/react-query"
import { faMap } from "@fortawesome/pro-solid-svg-icons"

import IconButton from "../../components/IconButton"
import { Toast } from "../../components/Toast"
import { useGetPartnersLandownerGeodownload } from "../../hooks"
import { genericErrMsg } from "../../api/auth"
import { GeoDownloadResponseTypes } from "@/types/partners"

interface PartnersDownloadPropertyBoundariesTypes {
  apId: number
}

const PartnersDownloadPropertyBoundaries = ({
  apId,
}: PartnersDownloadPropertyBoundariesTypes) => {
  const queryClient = useQueryClient()

  const { mutateAsync: boundsGeodownload } = useGetPartnersLandownerGeodownload<
    GeoDownloadResponseTypes,
    AxiosError,
    number
  >(queryClient, apId, {
    onSuccess: ({ download_link }) => {
      window.location.href = download_link
    },
    onError: (error: AxiosError) => {
      Toast.error(error?.message || genericErrMsg)
    },
  })

  const handleBoundsGeodownload = async () => {
    await boundsGeodownload(apId)
  }

  return (
    <div className="w-full p-4">
      <IconButton
        onClick={() => {
          handleBoundsGeodownload()
        }}
        icon={faMap}
        text="Download Property Boundaries"
        className="leading-6"
      />
    </div>
  )
}
export default PartnersDownloadPropertyBoundaries
