import { GoogleLogin } from "@react-oauth/google"

interface SsoAuthButtonsTypes {
  onSuccess: (values: any) => void
  onError: () => void
}

export const ContinueSeparator = () => (
  <div className="flex items-center">
    <div className="flex-1 border-t-1 border-cloud ml-4" />
    <span className="px-16 text-neutral-800 text-base font-normal">or</span>
    <div className="flex-1 border-t-1 border-cloud mr-4" />
  </div>
)

export const SsoAuthButtons = ({ onSuccess, onError }: SsoAuthButtonsTypes) => {
  return (
    <>
      <div
        className="flex flex-col items-center sso-auth-buttons"
        data-test="sso-auth-buttons"
      >
        <GoogleLogin
          // @ts-ignore
          className="google-button"
          text="continue_with"
          theme="outline"
          width="344px"
          onSuccess={(credentialResponse) => {
            onSuccess({ accessToken: credentialResponse.credential })
          }}
          onError={() => {
            onError()
          }}
        />
      </div>
    </>
  )
}
