import { ChangeEvent, useEffect, useRef } from "react"

interface IndeterminateCheckboxProps {
  checked: boolean
  indeterminate?: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const IndeterminateCheckbox = ({
  checked,
  indeterminate,
  onChange,
}: IndeterminateCheckboxProps) => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (typeof indeterminate === "boolean" && ref.current) {
      ref.current.indeterminate = !checked && indeterminate
    }
  }, [indeterminate, checked])

  return (
    <input
      type="checkbox"
      ref={ref}
      className="partner-table-checkbox"
      checked={checked}
      onChange={onChange}
    />
  )
}

export default IndeterminateCheckbox
