/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { cloneElement, ReactElement } from "react"
import cx from "classnames"

interface ButtonPairProps {
  primary:
    | ReactElement<any, any>
    | ((props: { className?: string }) => ReactElement)
  secondary:
    | ReactElement<any, any>
    | ((props: { className?: string }) => ReactElement)
  primaryHidden?: boolean
  secondaryHidden?: boolean
  className?: string
  gapClass?: string
}

type ExtractProps<T> = T extends ReactElement<infer P, any>
  ? P
  : Record<string, never>

export const ButtonPair = ({
  primary,
  secondary,
  primaryHidden = false,
  secondaryHidden = false,
  className,
  gapClass = "gap-4",
}: ButtonPairProps) => {
  const primaryFn =
    typeof primary === "function"
      ? primary
      : (
          newPrimaryProps: Partial<ExtractProps<typeof primary>> & {
            className?: string
          }
        ) =>
          cloneElement(primary, {
            ...primary.props,
            ...newPrimaryProps,
            className: cx(primary.props.className, newPrimaryProps.className),
          })

  const secondaryFn =
    typeof secondary === "function"
      ? secondary
      : (
          newSecondaryProps: Partial<ExtractProps<typeof secondary>> & {
            className?: string
          }
        ) =>
          cloneElement(secondary, {
            ...secondary.props,
            ...newSecondaryProps,
            className: cx(
              secondary.props.className,
              newSecondaryProps.className
            ),
          })

  return (
    <div
      className={cx(
        "flex sm:inline-flex flex-col sm:flex-row-reverse",
        !secondaryHidden ? gapClass : null,
        className
      )}
    >
      {primaryFn({
        className: cx(
          "btn2 btn2-primary font-semibold btn2-block",
          "sm:btn2-inline-block sm:inline-block",
          { "!hidden sm:!hidden": primaryHidden }
        ),
      })}
      {secondaryFn({
        className: cx(
          "btn2 btn2-outline-primary font-semibold btn2-block",
          "sm:btn2-inline-block sm:inline-block",
          { "!hidden sm:!hidden": secondaryHidden }
        ),
      })}
    </div>
  )
}
