import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { QueryStatus, useQueryClient } from "@tanstack/react-query"
import MapGL, { Source, Layer, Popup } from "@urbica/react-map-gl"
import {
  FeatureCollection,
  Geometry,
  MultiPolygon,
  Properties,
} from "@turf/helpers"
import copy from "copy-to-clipboard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy } from "@fortawesome/pro-solid-svg-icons"

import { Toast } from "../../components/Toast"
import ToggleSwitch from "../../components/ToggleSwitch"
import { LayerPicker } from "../../components/LayerPicker"
import { Spinner } from "../../components/Spinner"
import { MAPBOX_TOKEN, mapStyles } from "../../shared/constants"
import PartnersDownloadPropertyBoundaries from "./PartnersDownloadPropertyBoundaries"
import { EligibilityLayer } from "../ProjectDetails/ProjectMap/ProjectMapVisualization"
import { getViewportFromFeature } from "../ProjectDetails/ProjectMap/helpers"
import ProjectMapParcels from "../ProjectDetails/ProjectMap/ProjectMapParcels"
import { useAccessToken } from "../../stores"
import {
  useGetPartnerTableRowParcelAssessment,
  useGetPartnerTableRowTileUrls,
} from "../../hooks"
import { usePartnersStore } from "../../stores/usePartnersStore"
import "mapbox-gl/dist/mapbox-gl.css"
import { getViewportFromFeatures, transformBounds } from "../../shared/utils"
import { ViewportTypes } from "../../types"
import { TileURLsTypes } from "../../types/tiles"
import { LayerType } from "../../types/mapbox"

interface PartnersAccountMapTypes {
  accountStatus: QueryStatus
  bounds: string
}

const PartnersAccountMap = ({
  accountStatus,
  bounds,
}: PartnersAccountMapTypes) => {
  const queryClient = useQueryClient()
  const accessToken = useAccessToken()
  const [layer, setLayer] = useState<LayerType>("aerial")
  const [activeFeature, setActiveFeature] = useState<number | null>(null)
  const [showEligibilityLayer, setShowEligibilityLayer] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [popupCoordinates, setPopupCoordinates] = useState<{
    lat: number | null
    lng: number | null
  }>({
    lat: null,
    lng: null,
  })
  const [parcelData, setParcelData] = useState<FeatureCollection<
    Geometry,
    Properties
  > | null>(null)
  const [tileData, setTileData] = useState<TileURLsTypes | null>(null)

  const { viewport, activeRow, setViewport } = usePartnersStore()

  const copyCoordinates = () => {
    const coordinates = `${popupCoordinates.lat}, ${popupCoordinates.lng}`
    try {
      copy(coordinates)
      Toast.success(`Coordinates copied to clipboard: ${coordinates}`)
    } catch (error) {
      Toast.error("Failed to copy coordinates:")
    }
  }

  const { mutateAsync: getTileUrls } = useGetPartnerTableRowTileUrls(
    queryClient,
    activeRow?.id as number,
    {
      onSuccess: (data: TileURLsTypes) => {
        setTileData(data)
      },
      onError: () => {
        setTileData(null)
      },
    }
  )

  const { mutateAsync: getParcelAssessment } =
    useGetPartnerTableRowParcelAssessment({
      onSuccess: (data: FeatureCollection<Geometry, Properties>) => {
        queryClient.setQueryData(
          [
            "partners",
            "ncapi",
            "parcel",
            "assessment",
            "ap",
            activeRow?.id?.toString(),
          ],
          data
        )
        if (activeFeature === null) {
          setActiveFeature(0)
        }
        setParcelData(data)
        setViewport(getViewportFromFeature(data.features[0]))
      },
      onError: () => {
        setActiveFeature(null)
        setParcelData(null)
        // DEV: fallback to just property bounds if something fails getting parcel-level data
        setViewport(
          getViewportFromFeatures(
            transformBounds(JSON.parse(bounds) as MultiPolygon)
          ) as ViewportTypes
        )
      },
    })

  useEffect(() => {
    setActiveFeature(null)
    if (activeRow?.id && bounds) {
      const parsedBounds = JSON.parse(bounds)
      const numVertices = parsedBounds?.coordinates?.reduce(
        (flatArr: string[], subList: string) => flatArr.concat(subList),
        []
      ).length
      // too many vertices, we crash trying to load parcel-level data so just show the property bounds
      if (numVertices >= 200) {
        // reset data, else it is carried over from previous selection
        setParcelData(null)
        setTileData(null)
        // just show property bounds, not parcel-level data
        setViewport(
          getViewportFromFeatures(
            transformBounds(parsedBounds as MultiPolygon)
          ) as ViewportTypes
        )
      } else {
        getTileUrls(activeRow?.id)
        getParcelAssessment(activeRow?.id)
      }
    }
  }, [activeRow, bounds, getParcelAssessment, getTileUrls, setViewport])

  const showEligibilityOption = parcelData && tileData

  if (viewport === null || accountStatus === "pending") {
    return (
      <div className="card card-content flex justify-center items-center h-[656px] relative overflow-hidden mb-6 p-0">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="partners-account-map card card-content relative overflow-hidden mb-6 p-0">
      <div className="lg:flex relative overflow-hidden">
        <div
          className="map w-full h-[650px] relative"
          onContextMenu={(e) => e.preventDefault()}
        >
          <MapGL
            style={{ width: "100%", height: "650px" }}
            mapStyle={mapStyles[layer as keyof typeof mapStyles].url}
            accessToken={MAPBOX_TOKEN}
            onViewportChange={setViewport}
            viewportChangeMethod="flyTo"
            viewportChangeOptions={{
              duration: 1000,
            }}
            dragRotate={false}
            onMousedown={(e: {
              originalEvent?: any
              lngLat: { lat: number | null; lng: number | null }
            }) => {
              if (e.originalEvent.button === 2) {
                const { lngLat } = e
                setPopupCoordinates(lngLat)
                setShowPopup(true)
              } else if (e.originalEvent.button === 0) {
                setShowPopup(false)
              }
            }}
            transformRequest={(url) => {
              if (
                url.includes("tipg") ||
                url.includes("amazonaws.com/collections")
              ) {
                return {
                  url: url,
                  headers: { Authorization: `Bearer ${accessToken}` },
                  credentials: "include",
                }
              }
              return { url }
            }}
            {...viewport}
          >
            <Source
              id="route"
              type="geojson"
              data={
                activeFeature !== null
                  ? {
                      type: "FeatureCollection",
                      features: parcelData?.features,
                    }
                  : JSON.parse(bounds)
              }
            />
            <Layer
              id="route"
              source="route"
              type="line"
              paint={{
                "line-color": "#3bb2d0",
                "line-width": 3,
              }}
            />
            {tileData && (
              <EligibilityLayer
                showEligibilityLayer={showEligibilityLayer}
                tileData={tileData}
              />
            )}

            {showPopup && popupCoordinates.lat !== null ? (
              <Popup
                latitude={popupCoordinates.lat}
                longitude={popupCoordinates.lng as number}
                closeButton={false}
                closeOnClick={false}
              >
                <p className="flex flex-col mb-2">
                  <span>Latitude: {popupCoordinates.lat}</span>
                  <span>Longitude: {popupCoordinates.lng}</span>
                </p>
                <button onClick={copyCoordinates} className="link">
                  <FontAwesomeIcon
                    icon={faCopy}
                    className="fa-regular fa-copy"
                  />{" "}
                  Copy Coordinates
                </button>
              </Popup>
            ) : null}
          </MapGL>
          {showEligibilityOption && (
            <div className="absolute top-4 left-4 block bg-white border border-dusk-50 rounded px-2 py-[7px]">
              <ToggleSwitch
                label={
                  <>
                    Show <span className="font-normal">Eligibility</span>
                  </>
                }
                value={showEligibilityLayer}
                onChange={() =>
                  setShowEligibilityLayer((oldValue) => !oldValue)
                }
              />
            </div>
          )}

          <div className="absolute top-4 right-4 hidden  sm:block">
            <LayerPicker
              layer={layer}
              onLayerChange={(layer) => {
                setLayer(layer)
              }}
            />
          </div>
        </div>
        {showEligibilityOption && (
          <ProjectMapParcels
            isIneligible={false}
            parcelData={parcelData}
            activeFeature={activeFeature}
            setActiveFeature={setActiveFeature}
            setViewport={
              setViewport as Dispatch<SetStateAction<ViewportTypes | null>>
            }
            className="partner-app-account-map"
          />
        )}
      </div>
      <PartnersDownloadPropertyBoundaries apId={activeRow?.id as number} />
    </div>
  )
}

export default PartnersAccountMap
