// will replace pages/Login.js
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { AxiosError } from "axios"
import { useLocation, useNavigate } from "react-router"
import { Formik, FormikHelpers } from "formik"
import * as Yup from "yup"
import { OptimizelyContext } from "@optimizely/react-sdk"

import * as auth from "../../api/auth"
import EmailLoginFormComponent from "./EmailLoginFormComponent"
import {
  identifyUserForIntegrations,
  sendAnalyticsEvent,
} from "../../api/integrations"
import { useLogin } from "../../hooks"
import { useSessionStore } from "../../stores"
import { AuthDataTypes } from "../../types/auth"

interface EmailLoginFormTypes {
  authData: AuthDataTypes
  setAuthData: Dispatch<SetStateAction<AuthDataTypes>>
  inputEmail: string
}

const VITE_APP_PARTNER_VIEW = import.meta.env.VITE_APP_PARTNER_VIEW

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Please provide your password."),
})

const EmailLoginForm = ({
  authData,
  setAuthData,
  inputEmail,
}: EmailLoginFormTypes) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { optimizely } = useContext(OptimizelyContext)
  const { identified, setIdentified } = useSessionStore((state) => state)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { mutateAsync: login } = useLogin({
    onSuccess: (
      data: { profile_type: string },
      variables: { email: string }
    ) => {
      identifyUserForIntegrations(
        variables.email,
        optimizely,
        identified,
        setIdentified
      )
      sendAnalyticsEvent({ action: "login", label: "Landowners Login" })
      // DEV: multi-account users have to select an account context before viewing programs
      const landingPage =
        data?.profile_type === "multi_account" && VITE_APP_PARTNER_VIEW === "0"
          ? "/accounts"
          : "/"
      // Complete any existing login state, https://reactrouter.com/web/example/auth-workflow
      // DEV: They use `replace` but I'd rather keep `login` on the history stack
      const redirect: string = location.state?.from || landingPage
      navigate(redirect)
    },
    onError: (error: AxiosError<unknown, any>) => {
      if (error.response) {
        // Browser received a response
        if (auth.isAuthenticationError(error)) {
          setErrorMessage("Invalid password")
        } else {
          setErrorMessage(auth.genericErrMsg)
        }
      } else {
        setErrorMessage(auth.genericErrMsg)
      }
    },
  })

  const handleSubmit = async (
    values: AuthDataTypes,
    { setSubmitting }: FormikHelpers<AuthDataTypes>
  ) => {
    setErrorMessage(null)
    await login({ email: inputEmail, password: values.password })
    setSubmitting(false)
  }

  return (
    <Formik<AuthDataTypes>
      initialValues={{
        password: "",
        ...authData,
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <EmailLoginFormComponent
          {...formikProps}
          email={inputEmail}
          errorMessage={errorMessage}
          setAuthData={setAuthData}
        />
      )}
    </Formik>
  )
}

export default EmailLoginForm
